import classNames from 'classnames'
import React from 'react'
import AuthButton from 'components/AuthButton'
import FacebookAuthButton from 'components/FacebookAuthButton'
import Head from 'components/Head'
import Input from 'components/Input'
import MediaObject from 'components/MediaObject'
import Text from 'components/Text'
import Home from 'components/landing/Home'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import styles from './HomeSpecial.module.scss'

export default function HomeSpecial() {
  const globalContext = useGlobalContext()
  const [email, setEmail] = React.useState('')

  async function handleAuthButtonClick() {
    await globalContext.analytics?.trackEvent('Sign up: Continue with email: Click')
  }

  async function handleClose() {
    await globalContext.analytics?.trackEvent('Close Sign up modal')
  }

  const showFacebook = false // rdd.browserName !== 'Chrome WebView'
  const title = 'Seated and standing online exercise classes'

  return (
    <>
      <Head bodyAttributes={{ class: 'body---hide-header' }} />
      <Home
        landingHeroVariant
        heroProps={{
          title,
          subtext: (
            <>
              Designed for older adults, by clinical experts. Check if you’re eligible for Bold at{' '}
              <Text weight="semibold">no cost</Text> through your health plan, and get moving today.
            </>
          ),
          authProps: (
            <MediaObject
              figure={
                <AuthButton
                  authProps={{
                    ctaText: 'Check my eligibility',
                    signUpEmail: email,
                  }}
                  type="sign-up"
                  color="yellow"
                  full
                  size="large"
                  className={styles['sign-up-form--submit-button']}
                  onClick={handleAuthButtonClick}
                  onClose={handleClose}>
                  Check my eligibility
                </AuthButton>
              }
              figurePosition="right"
              reset="mobile"
              size="xsmall">
              <Input
                autoComplete="email"
                name="email"
                placeholder="Enter your email address"
                size="large"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </MediaObject>
          ),
          partners: [],
        }}
        howProps={{
          authButton: showFacebook ? <FacebookAuthButton type={events.SIGN_UP} /> : undefined,
        }}
        className={classNames('HomeSpecial')}
      />
    </>
  )
}
